<template>
  <section class="new-arrival">
    <!-- <div class="container">
      <div class="row">
        <div class="col heading-title text-center">
          <h2 class="title">Best Seller</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col slider-product">
        <PuSkeleton v-if="isLoading" height="300px" />
        <VueSlickCarousel
          v-bind="slickSettings"
          class="slider-product-box"
          v-else-if="products.length"
        >
          <ProductBox
            :product="product"
            v-for="(product, index) in products"
            :key="index"
            class="v-align-top"
          />
        </VueSlickCarousel>
        <div class="pt-3 pt-lg-4 text-center">
          <router-link
            :to="'/products/bestseller'"
            class="btn btn-wz-outline-gold mt-0"
            >VIEW MORE
          </router-link>
        </div>
      </div>
    </div> -->
    <b-skeleton-wrapper :loading="isLoading"> 
      <template #loading>
        <div class="container">
          <div class="row">
            <div class="col heading-title text-center">
              <b-skeleton class="mx-auto mb-3" height="42px" width="200px"></b-skeleton>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="homepage-skeleton-product-box">
              <ProductBoxSkeleton v-for="i in 5" :key="`best-seller-product-box-skeleton-${i}`" />
            </div>
            <b-skeleton class="mx-auto mt-3 mt-lg-4" type="button" width="137px" height="46px"></b-skeleton>
          </div>
        </div>
      </template>
      <template #default>
        <div class="container">
          <div class="row">
            <div class="col heading-title text-center">
              <h2 class="title">Best Seller</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col slider-product">
            <VueSlickCarousel
              v-bind="slickSettings"
              class="slider-product-box"
              v-if="products.length"
            >
              <ProductBox
                :product="product"
                v-for="(product, index) in products"
                :key="index"
                class="v-align-top"
              />
            </VueSlickCarousel>
            <div class="pt-3 pt-lg-4 text-center">
              <router-link
                :to="'/products/bestseller'"
                class="btn btn-wz-outline-gold mt-0"
                >VIEW MORE
              </router-link>
            </div>
          </div>
        </div>
      </template>
    </b-skeleton-wrapper>

    <div class="container pt-4">
      <hr />
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import ProductBox from "@/components/mains/ProductBox.vue";
const ProductBoxSkeleton = () => import("@/components/skeleton/ProductBoxSkeleton.vue");

export default {
  name: "BestSeller",
  components: {
    VueSlickCarousel,
    ProductBox,
    ProductBoxSkeleton,
  },
  data() {
    return {
      isLoading: true,
      products: [],
      slickSettings: {
        // centerMode: true,
        // centerPadding: "20px",
        // slidesToShow: 1,
        // slidesPerRow: 5,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: true,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              arrows: false,
              // centerMode: true,
              // centerPadding: "0px",
              // slidesToShow: 1,
              // slidesPerRow: 3,
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 481,
            settings: {
              arrows: false,
              // centerMode: true,
              rows: 2,
              // centerPadding: "0px",
              // slidesToShow: 1,
              // slidesPerRow: 2,
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      },
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
  },
  watch: {
    "$store.getters.getResellerData"(resellerData) {
      if (resellerData.status_verify) this.getBestSellerProducts();
    },
  },
  mounted() {
    this.getBestSellerProducts();
  },
  methods: {
    getBestSellerProducts() {
      this.$api.product.getProductList(0, 10, [{"property":"numOfOrder","direction":"desc"}], null, true)
        .then((response) => this.setProducts(response.data))
        .catch((error) => console.log(error));
    },
    setProducts(data) {
      this.products = data["data"]["result"];
      this.isLoading = false
    },
  },
};
</script>