<template>
  <!-- <section class="countdown" v-if="promo && countdown"> -->
    <!-- <div class="container">
      <div class="row">
        <div class="col heading-title text-center">
          <h2 class="title mb-3">{{ promo.name }}</h2>
          <div class="custom-clock" v-if="countdown.hr < 24">
            <div
              v-for="i in countdown.string.length"
              :key="i"
              class="d-inline ml-1 bold"
              :class="{
                'border p-2 rounded bg-gold text-white':
                  countdown.string[i - 1] != ':',
              }"
            >{{ countdown.string[i - 1] }}</div>
          </div>
          <span v-else class="roboto bg-gold text-white text-small py-2 px-4">
            Promo ends on {{ formatDate(promo.active_to) }}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col slider-product">
        <PuSkeleton v-if="isLoading" height="300px" />
        <VueSlickCarousel
          v-bind="slickSettings"
          class="slider-product-box"
          v-else-if="products.length"
        >
          <ProductBox
            :product="product"
            v-for="(product, index) in products"
            :key="index"
            class="v-align-top"
          />
        </VueSlickCarousel>

        <div class="pt-3 pt-lg-4 text-center">
          <router-link
            :to="'/products/' + promo.slug"
            class="btn btn-wz-outline-gold mt-0"
          >
            VIEW MORE
          </router-link>
        </div>
      </div>
    </div> -->
  <section class="countdown">
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="container">
          <div class="row">
            <div class="col heading-title text-center">
              <b-skeleton class="mx-auto mb-3" height="42px" width="200px"></b-skeleton>
              <b-skeleton class="mx-auto" height="31px" width="150px"></b-skeleton>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="homepage-skeleton-product-box">
              <ProductBoxSkeleton v-for="i in 5" :key="`countdown-product-box-skeleton-${i}`" />
            </div>
            <b-skeleton class="mx-auto mt-3 mt-lg-4" type="button" width="137px" height="46px"></b-skeleton>
          </div>
        </div>
      </template>
      <template #default>
        <template v-if="countdown && promo">
          <div class="container">
            <div class="row">
              <div class="col heading-title text-center">
                <h2 class="title mb-3">{{ promo.name }}</h2>
                <div class="custom-clock" v-if="countdown.hr < 24">
                  <div
                    v-for="i in countdown.string.length"
                    :key="i"
                    class="d-inline ml-1 bold"
                    :class="{
                      'border p-2 rounded bg-gold text-white':
                        countdown.string[i - 1] != ':',
                    }"
                  >{{ countdown.string[i - 1] }}</div>
                </div>
                <span v-else class="roboto bg-gold text-white text-small py-2 px-4">
                  Promo ends on {{ formatDate(promo.active_to) }}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col slider-product">
              <VueSlickCarousel
                v-bind="slickSettings"
                class="slider-product-box"
                v-if="products.length"
              >
                <ProductBox
                  :product="product"
                  v-for="(product, index) in products"
                  :key="index"
                  class="v-align-top"
                />
              </VueSlickCarousel>

              <div class="pt-3 pt-lg-4 text-center">
                <router-link
                  :to="'/products/' + promo.slug"
                  class="btn btn-wz-outline-gold mt-0"
                >
                  VIEW MORE
                </router-link>
              </div>
            </div>
          </div>
        </template>
      </template>
    </b-skeleton-wrapper>
    <div class="container pt-4">
      <hr />
    </div>
  </section>
</template>
<script>
import { toaster, formatter } from "@/_helpers";
import VueSlickCarousel from "vue-slick-carousel";
import ProductBox from "@/components/mains/ProductBox";
const ProductBoxSkeleton = () => import("@/components/skeleton/ProductBoxSkeleton.vue");
import moment from "moment";

export default {
  name: "Countdown",
  components: {
    VueSlickCarousel,
    ProductBox,
    ProductBoxSkeleton,
  },
  data() {
    return {
      products: [],
      promo: null,
      countdown: null,
      slickSettings: {
        // centerMode: true,
        // centerPadding: "20px",
        // slidesToShow: 1,
        // slidesPerRow: 5,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: true,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              arrows: false,                                                         
              // centerMode: true,
              // centerPadding: "0px",
              // slidesToShow: 1,
              // slidesPerRow: 3,
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 481,
            settings: {
              arrows: false,
              // centerMode: true,
              rows: 2,
              // centerPadding: "0px",
              // slidesToShow: 1,
              // slidesPerRow: 2,
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      },
      isLoading: true,
    };
  },
  computed: {
    resellerData() {
      return this.$store.getters.getResellerData;
    },
    isLoggedIn: {
      get() {
        return this.$store.getters.getIsLoggedIn;
      }
    }
  },
  watch: {
    promo(newVal) {
      if (this.resellerData?.status_verify) this.getProducts(newVal.slug);
    },
    isLoggedIn() {
      this.getPromotions()
    }
  },
  mounted() {
    this.getPromotions()
  },
  methods: {
    async getPromotions() {
      try {
        const res = await this.$api.cms.getPromotions()
        if (res.status === 200) {
          const promo = res.data.data;
          const forHomepage = promo.filter((el) => el.homepage_display);
          if (forHomepage.length) {
            this.promo = forHomepage.length > 1
              ? forHomepage[Math.floor(Math.random() * forHomepage.length)]
              : forHomepage[0];
            this.getProducts(this.promo.slug);
            setInterval(() => 
              this.getCountdown(this.formatEpochMsToLocalTime(this.promo.active_to_epoch, 'YYYY-MM-DD HH:mm'))
            , 1000)
          }
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        console.error(e)
      }
    },
    async getProducts(slug) {
      try {
        const res = await this.$api.product.getProductList(0, 10, null, {
          logic: "and",
          filters: [
            {
              property: "slug",
              operator: "equals",
              values: [
                {
                  value: slug,
                },
              ],
            },
          ],
        })
        if (res.status === 200) this.products = res.data.data.result
        else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger');
        console.error(e);
      }
      this.isLoading = false
    },
    getCountdown(deadline) {
      const distance = new Date(deadline).getTime() - new Date().getTime();
      this.countdown = {
        hr: Math.floor(distance / 1000 / 3600),
        min: Math.floor(distance / 1000 / 60) % 60,
        sec: Math.floor(distance / 1000) % 60,
        get string() {
          return `${this.hr < 10 ? "0" + this.hr : this.hr}:${this.min < 10 ? "0" + this.min : this.min}:${this.sec < 10 ? "0" + this.sec : this.sec}`
        }
      };
    },
    formatEpochMsToLocalTime(value, format = 'DD MMMM YYYY') {
      return formatter.epochMsToLocalTime(value, format)
    },
    formatDate(date, format = "D MMMM YYYY") {
      return date ? moment(String(date)).format(format).toString() : date;
    },
  },
};
</script>