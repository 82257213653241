<template>
  <section class="top-banner pt-lg-0">
    <template v-if="$store.getters.getResellerData && !$store.getters.getResellerData.is_membership_expired">
      <div class="d-flex d-lg-none py-2 px-3 bg-brown text-white justify-content-between align-items-center font-14px">
        Buy Membership
        <router-link tag="button" class="btn btn-buynow-gold-outline" to="/bus-partner-membership">Buy Now</router-link>
      </div>
    </template>
    <VueSlickCarousel
      v-bind="slickSettings"
      class="big-banner"
      v-if="banners.length"
    >
      <div v-for="(banner, index) in banners" :key="index">
        <!-- <router-link v-if="!resellerData && redirect(banner.slug, banner.open_new_tab, banner.link_url, banner.flashSale).indexOf('/register-bus-partner') >= 0" to="#" v-b-modal.modal-register-reseller>
          <img-skeleton :src="banner.image_url" classActive="d-none d-md-block" :title="banner.name" :alt="banner.name" />
          <img-skeleton :src="banner.image_url_responsive" classActive="d-md-none" height="300px" :title="banner.name" :alt="banner.name" />
        </router-link>
        <router-link v-else :to="redirect(banner.slug, banner.open_new_tab, banner.link_url, banner.flashSale)">
          <img-skeleton :src="banner.image_url" classActive="d-none d-md-block" :title="banner.name" :alt="banner.name" />
          <img-skeleton :src="banner.image_url_responsive" classActive="d-md-none" height="300px" :title="banner.name" :alt="banner.name" />
        </router-link> -->
        <UrlHandler :url="banner.url" v-if="!resellerData && redirect(banner.slug, banner.open_new_tab, banner.link_url, banner.flashSale).indexOf('/register-bus-partner') >= 0" to="#" v-b-modal.modal-register-reseller>
          <img-skeleton :src="banner.image_url" classActive="d-none d-md-block" :title="banner.name" :alt="banner.name" />
          <img-skeleton :src="banner.image_url_responsive" classActive="d-md-none" height="300px" :title="banner.name" :alt="banner.name" />
        </UrlHandler>
        <UrlHandler :url="redirect(banner.slug, banner.open_new_tab, banner.link_url, banner.flashSale)" v-else>
          <img-skeleton :src="banner.image_url" classActive="d-none d-md-block" :title="banner.name" :alt="banner.name" />
          <img-skeleton :src="banner.image_url_responsive" classActive="d-md-none" height="300px" :title="banner.name" :alt="banner.name" />
        </UrlHandler>
      </div>
    </VueSlickCarousel>
    <b-skeleton-img class="big-banner-skeleton" v-else></b-skeleton-img>
  </section>
</template>

<script>
import { cmsService } from "@/_services";
import VueSlickCarousel from "vue-slick-carousel";
const UrlHandler = () => import('@/components/mains/UrlHandler');

export default {
  name: "TopBanner",
  components: {
    VueSlickCarousel,
    UrlHandler
  },
  data() {
    return {
      banners: [],
      slickSettings: {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 5000,
      },
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    resellerData() {
      return this.$store.getters.getResellerData;
    },
    token() {
      return this.$store.getters.getUserToken;
    },
  },
  watch: {
    token() {
      this.getBanner();
    },
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    async getBanner() {
      try {
        const res = await cmsService.getBanner();
        if (res.status === 200) {
          this.setBanner(res.data.data);
        }
      } catch (e) {
        console.error(e);
      }
    },
    setBanner(data) {
      this.banners = data;
    },
    redirect(url, open, extUrl, type) {
      const retVal = !extUrl
        ? type ? "/flash-sale/" + url : "/products/" + url
        // : extUrl.includes("whizliz.com") ? extUrl.split("whizliz.com")[1] : extUrl
        : extUrl
      return this.resellerData && retVal.indexOf('/register-bus-partner') >= 0 ? '/bus-partner-referral' : retVal
    },
  },
};
</script>
<style scoped>
div.big-banner, .big-banner-skeleton {
  z-index: 0;
  /* min-height: 113.25vh; */
  min-height: 108vh;
}
@media only screen and (max-width: 768px) {
  .big-banner, .big-banner-skeleton {
    min-height: 300px !important;
  }
}
</style>
