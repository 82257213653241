<template>
  <div :class="showChevron ? 'p-1' : 'p-2'">
    <router-link class="card h-100 shadow" :to="`/shop-by-brands/${model.slug}-official-store-${model.id}`">
    <!-- <div class="card h-100 shadow"> -->
      <div class="d-flex align-items-center">
        <div class="brand-logo-container">
          <!-- <router-link :to="`/shop-by-brands/${model.slug}-official-store-${model.id}`"> -->
          <div class="brand-logo-new bg-white" :style="{'--background' : `url('${model.image_banner_square}')`}">
            <div class="brand-logo-prime" v-if="model.check_prime_merchant"></div>
          </div>
          <!-- </router-link> -->
        </div>
        <div class="brand-name-new pl-3">
          <!-- <router-link :to="`/shop-by-brands/${model.slug}-official-store-${model.id}`" class="text-black"> -->
          <h2 class="roboto mb-1 line-clamp-1 text-black" :title="model.name">{{model.name}}</h2>
          <!-- </router-link> -->
          <p class="text-grey m-0 line-clamp-1 wz-pointer-event-none" :title="model.description">{{model.description}}</p>
        </div>
        <!-- Takeout arrow right icon -->
        <!-- <router-link :to="`/shop-by-brands/${model.slug}-official-store-${model.id}`" class="ml-auto text-black" :class="{'d-none d-lg-block' : !showChevron}">
          <fawesome-pro icon="chevron-right" variant="far" size="2x"/>
        </router-link> -->
      </div>
      <div class="row no-gutters mt-3" v-if="!showChevron">
        <div class="col h-100 px-1" :class="{'d-none d-lg-block' : i > 0}" v-for="(highlight, i) in [...model.highlight_product, ...Array(3 - model.highlight_product.length).fill(null)]" :key="i">
          <!-- if highlight is null -->
          <!-- <router-link v-if="!highlight" :to="`/shop-by-brands/${model.slug}-official-store-${model.id}`"> -->
          <b-img v-if="!highlight" :src="require('@/assets/img/default-image.png')" alt="" fluid class="rounded p-5 p-lg-4 default-image" />
          <!-- </router-link> -->
          <!-- else if not null -->
          <template v-else>
            <!-- if url contains whizliz.com or no highlight link_url -->
            <router-link
              v-if="!highlight.link_url"
              :to="highlight.product ? `/product/${highlight.product.slug}` : `/shop-by-brands/${model.slug}-official-store-${model.id}`"
            >
              <b-img ref="highlight" :src="(highlight.image_url || highlight.product && highlight.product.product_images[0].medium_image_url) + '?w=256'" :alt="setHighlightProductImgAltText(highlight)" fluid class="rounded" />
            </router-link>
            <router-link
              v-else-if="highlight.link_url && highlight.link_url.indexOf('whizliz.com') >= 0"
              :to="highlight.link_url.split('whizliz.com')[1] || `/shop-by-brands/${model.slug}-official-store-${model.id}`"
            >
              <b-img ref="highlight" :src="(highlight.image_url || highlight.product && highlight.product.product_images[0].medium_image_url) + '?w=256'" :alt="setHighlightProductImgAltText(highlight)" fluid class="rounded" />
            </router-link>
            <!-- else if not -->
            <a v-else :href="(highlight.link_url && !/^https?:\/\//.test(highlight.link_url) ? `https://${highlight.link_url}` : highlight.link_url)" target="_blank">
              <b-img ref="highlight" :src="(highlight.image_url || highlight.product && highlight.product.product_images[0].medium_image_url) + '?w=256'" :alt="setHighlightProductImgAltText(highlight)" fluid class="rounded" />
            </a>
          </template>
        </div>
      </div>
    <!-- </div> -->
    </router-link>
  </div>
</template>
<script>
// import { toaster } from "@/_helpers";

export default {
  name: "BrandShowcase",
  props: ["brand", "showChevron"],
  data() {
    return {
      model: this.brand,
    }
  },
  // mounted() {
  //   if (!this.showChevron) this.checkHighlights()
  // },
  methods: {
    setHighlightProductImgAltText(highlight) {
      return highlight.product?.name ? `${highlight.brand.name} ${highlight.product.name}` : '';
    },
  //   checkHighlights() {
  //     const highlights = this.model.highlight_product
  //     if (highlights)
  //       for (const i in highlights)
  //         this.getProductImg(i)
  //     else setTimeout(() => this.checkHighlights(), 300)
  //   },
  //   async getProductImg(index) {
  //     const highlight = this.model.highlight_product[index]
  //     const condition = !highlight.image_url || /(ne(t|t\/)|co(m|m\/))$/.test(highlight.image_url)
  //     const productId = highlight.product?.id
  //     if (condition && productId) {
  //       try {
  //         const res = await this.$api.product.getProductDetail(productId)
  //         if (res.status === 200) {
  //           const image = res.data.data.product_images[0]
  //           this.model.highlight_product[index].image_url = image.medium_image_url
  //         }
  //         else toaster.make(res.data.message, "danger")
  //       } catch (e) {
  //         console.error(e);
  //       }
  //     }
  //   },
  },
  watch: {
    brand(newVal) {
      this.model = newVal
    }
  }
}
</script>
