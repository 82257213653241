<template>
  <div class="hot-icon" :class="{'hide' : !$store.getters.getShowHotIcon}" v-if="hotIconList[0]">
    <div class="toggle-icon" @click="toggleIcon" v-if="isLoaded">
      <font-awesome icon="chevron-right" />
    </div>
    <!-- Internal URL (whizliz.com) -->
    <template v-if="new RegExp(feUrl, 'i').test(hotIconList[0].url)">
      <router-link :to="hotIconList[0].url.replace(feUrl, '')">
        <img class="cursor-pointer" width="100" :alt="hotIconList[0].name" :src="hotIconList[0].image_url" @load="isLoaded = true"/>
      </router-link>
    </template>
    <!-- External URL -->
    <template v-else>
      <a :href="hotIconList[0].url" target="_blank">
        <img class="cursor-pointer" width="100" :alt="hotIconList[0].name" :src="hotIconList[0].image_url" @load="onHotIconLoad"/>
      </a>
    </template>
  </div>
</template>

<script>
export default {
  name: "HotIcon",
  data() {
    return {
      isLoaded: false,
      hotIconList: []
    }
  },
  computed: {
    feUrl() {
      return process.env.VUE_APP_FE_URL;
    },
  },
  mounted() {
    this.getHotIcon()
  },
  methods: {
    async getHotIcon() {
      try {
        const res = await this.$api.user.getHotIcon()
        if(res.status === 200) {
          this.hotIconList = res.data.data
        }
      } catch (e) {
        console.error(e)
      }
    },
    onHotIconLoad() {
      this.isLoaded = true
    },
    toggleIcon() {
      this.$store.dispatch("setShowHotIcon", !this.$store.getters.getShowHotIcon)
    },
  },
};
</script>