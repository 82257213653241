<template>
  <!--MODAL SELL BACK-->
  <b-modal modal-class="p-0 rounded" id="modal-promo" hide-header hide-footer :hide-backdrop="!noCookie" centered static lazy>
    <div class="modal-body text-center">
      <button
        type="button"
        @click="$bvModal.hide('modal-promo')"
        class="close"
        data-dismiss="modal"
      >
        <img src="@/assets/img/ic-close.svg" alt="" />
      </button>
      <div class="promo-banner" v-if="selectedImage">
        <div class="border-bottom">
          <router-link :to="'/products/' + selectedImage.slug">
            <img
              :src="selectedImage.mobile"
              :alt="selectedImage.alt"
              class="w-100 rounded"
              @error="defaultBanner"
            />
          </router-link>
        </div>
        <router-link to="/promo" class="btn btn-wz-proceed p-2 my-3">
          FIND OUT!
        </router-link>
      </div>
    </div>
  </b-modal>
</template>
<script>
import moment from "moment";

export default {
  name: "ModalPopupPromo",
  data() {
    return {
      noCookie: document.cookie.indexOf("showPopupPromo=true") < 0,
      selectedImage: null,
    };
  },
  computed: {
    promos() {
      return this.$store.getters.getPromoList;
    }
  },
  methods: {
    setPromo(data) {
      const isRegisterReferral = this.$route.path.indexOf("register") >= 0
      if (!isRegisterReferral && this.noCookie) {
        document.cookie = "showPopupPromo=true; expires=" + this.getExpirationDate() + "; path=/";
        const promoDisplay = data.filter(el => el.popup_display)
        if (promoDisplay.length) this.randomImagePromo(promoDisplay);
      } 
    },
    randomImagePromo(data) {
      const index = Math.floor(Math.random() * data.length);
      // let index = ;
      this.selectedImage = {
        desktop: data[index].image_src,
        mobile: data[index].image_responsive_src,
        slug: data[index].slug,
        alt: data[index].image_description
      };
      this.$bvModal.show("modal-promo");
    },
    defaultBanner(img) {
      img.target.src = require("@/assets/img/banner-01.jpg");
    },
    getExpirationDate() {
      //UTC Format
      const tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);
      return moment(String(tomorrow))
        .utc()
        .format("ddd, DD MMM YYYY HH:mm:ss UTC");
    },
  },
  watch: {
    promos(newVal) {
      if (this.$helpers.other.isCrawlerBot()) return // Prevent Crawler Bot to indexing the modal popup promo
      this.setPromo(newVal)
    }
  }
};
</script>
