<template>
  <!-- <section class="why-whizliz">
    <div class="container-fluid">
      <div class="row row-cols-2 row-cols-lg-4 text-center flex-nowrap overflow-auto pb-2">
        <div class="col">
          <b-img-lazy width="100px" height="100px" :src="require('@/assets/img/buyback.svg')"
            alt="Buyback Guarantee"
            title="Buyback Guarantee"
          />
          <p class="pt-3 mb-0">Buyback Guarantee</p>
        </div>
        <div class="col">
          <b-img-lazy width="100px" height="100px" :src="require('@/assets/img/resize.svg')"
            alt="Free Returns &amp; Size Exchange"
            title="Free Returns &amp; Size Exchange"
          />
          <p class="pt-3 mb-0">Free Returns &amp; Size Exchange</p>
        </div>
        <div class="col">
          <b-img-lazy width="100px" height="100px" :src="require('@/assets/img/secure.svg')"
            alt="Secure Delivery Service"
            title="Secure Delivery Service"
          />
          <p class="pt-3 mb-0">Secure Delivery Service</p>
        </div>
        <div class="col">
          <b-img-lazy width="100px" height="100px" :src="require('@/assets/img/authentic.svg')"
            alt="Authentic Gold &amp; Diamond"
            title="Authentic Gold &amp; Diamond"
          />
          <p class="pt-3 mb-0">Authentic Gold &amp; Diamond</p>
        </div>
      </div>
    </div>
    <div v-if="!hasBanners" class="container pt-4">
      <hr />
    </div>
  </section> -->
  <section class="why-choose-whizliz">
    <b-img class="d-none d-lg-block m-auto" :src="require('@/assets/img/why-choose-whizliz.png')" title="Whizliz Benefit" alt="Whizliz Benefits Buyback Guarantee Free Return & Size Exchange Secure Delivery Service Authentic Gold & Diamond"></b-img>
    <div class="d-lg-none whizliz-benefit-mobile">
      <b-img :src="require('@/assets/img/buyback.png')" alt="Whizliz Benefit Buyback Guarantee"></b-img>
      <b-img :src="require('@/assets/img/free-returns.png')" alt="Whizliz Benefit Free Return & Size Exchange"></b-img>
      <b-img :src="require('@/assets/img/secure.png')" alt="Whizliz Benefit Secure Delivery Service"></b-img>
      <b-img :src="require('@/assets/img/authentic.png')" alt="Whizliz Benefit Authentic Gold & Diamond"></b-img>
    </div>
  </section>
</template>

<script>
export default {
  name: "WhyWhizliz",
  props: ["hasBanners"]
};
</script>
<style scoped>
.why-choose-whizliz {
  background: #FFEFD1;
}

.whizliz-benefit-mobile {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
}

.whizliz-benefit-mobile img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  padding: 16px;
}

/* Mobile View */
@media only screen and (max-width: 768px) {
  .whizliz-benefit-mobile img {
    padding: 12px;
  }
}
@media only screen and (max-width: 992px) {
  .why-choose-whizliz {
    padding: 16px;
    min-height: 120px;
  }
}
/* Desktop View */
@media only screen and (min-width: 992px) {
  .why-choose-whizliz {
    padding: 36px 0;
    min-height: 150px;
  }
}
</style>