<template>
  <section class="banner-box mt-3">
    <div class="container">
      <div class="row">
        <div class="col">
          <UrlHandler :url="banner.url">
            <picture>
              <source media="(min-width: 992px)" :srcset="banner.image_url" :title="banner.name" :alt="banner.name">
              <b-img-lazy class="mw-100 h-auto" :src="banner.image_mobile_url" :title="banner.name" :alt="banner.name"></b-img-lazy>
            </picture>
          </UrlHandler>
        </div>
        <!-- <div class="col">
          <a href="#" v-b-modal="'modalSellBack'">
            <img src="@/assets/img/banner-sellback.png" />
          </a>
        </div>
        <div class="col">
          <router-link to="products/redeemable">
            <img src="@/assets/img/banner-authentic.png" />
          </router-link>
        </div> -->
      </div>
    </div>
    <!-- <SellBack></SellBack> -->
  </section>
</template>

<script>
// import SellBack from "@/components/modals/SellBack.vue";
const UrlHandler = () => import('@/components/mains/UrlHandler');

export default {
  name: "BannerBox",
  props: ["banner"],
  components: {
    // SellBack,
    UrlHandler,
  },
};
</script>
