<template>
  <section class="pb-5">
    <!-- <div class="row">
      <div class="col heading-title text-center">
        <h2 class="title">Liz Magz</h2>
      </div>
    </div>
    <VueSlickCarousel
      v-bind="slickSettings"
      class="slider-product-box px-lg-5"
      v-if="magazines.length"
    >
      <div v-for="(magazine, i) in magazines" :key="i">
        <router-link
          class="find-your-style-container position-relative"
          :to="`/lizmagz/${magazine.slug}`"
        >
          <img-skeleton
            width="100%"
            height="100px"
            classActive="img-fluid w-100"
            :src="magazine.image_home_url"
            :title="magazine.title"
            :alt="magazine.image_alternate"
            @error="replaceByDefaultImage"
          />
          <div class="find-your-style-text-container m-0">
            <div class="text-boxed">
              {{magazine.title}}
            </div>
          </div>
          <div class="date-addon">{{ formatDate(magazine.createdAt) }}</div>
        </router-link> -->
        <!-- <div class="row no-gutters">
          <router-link class="col px-1 object-fit-fill" :to="`/lizmagz/${magazine.slug}`">
            <img fluid-grow class=""
              :src="magazine.image_home_url"
              :title="magazine.title"
              :alt="magazine.image_alternate"
              @error="replaceByDefaultImage"
            />
          </router-link>
          <div class="col px-1">
            <div class="magz-date">{{ formatDate(magazine.createdAt) }}</div>
            <h4 class="line-clamp-2">
              <router-link class="text-black" :to="`/lizmagz/${magazine.slug}`">{{ magazine.title }}</router-link>
            </h4>
            <router-link :to="`/lizmagz/${magazine.slug}`" class="btn btn-wz-outline-gold">READ MORE</router-link>
          </div>
        </div> -->
      <!-- </div>
    </VueSlickCarousel> -->

    <b-skeleton-wrapper :loading="isLoading"> 
      <template #loading>
        <div class="container">
          <div class="row">
            <div class="col heading-title text-center">
              <b-skeleton class="mx-auto mb-3" height="42px" width="200px"></b-skeleton>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="homepage-skeleton-article">
              <b-skeleton-img aspect="206:45"></b-skeleton-img>
            </div>
          </div>
        </div>
      </template>
      <template #default>
        <div class="row">
          <div class="col heading-title text-center">
            <h2 class="title">Liz Magz</h2>
          </div>
        </div>
        <VueSlickCarousel
          v-bind="slickSettings"
          class="slider-product-box px-lg-5"
          v-if="magazines.length"
        >
          <div v-for="(magazine, i) in magazines" :key="i">
            <router-link
              class="find-your-style-container position-relative"
              :to="`/lizmagz/${magazine.slug}`"
            >
              <img-skeleton
                width="100%"
                height="100px"
                classActive="img-fluid w-100"
                :src="magazine.image_home_url"
                :title="magazine.title"
                :alt="magazine.image_alternate"
                @error="replaceByDefaultImage"
              />
              <div class="find-your-style-text-container m-0">
                <div class="text-boxed">
                  {{magazine.title}}
                </div>
              </div>
              <div class="date-addon">{{ formatDate(magazine.createdAt) }}</div>
            </router-link>
          </div>
        </VueSlickCarousel>
      </template>
    </b-skeleton-wrapper>
  </section>
</template>

<script>
const VueSlickCarousel = () => import("vue-slick-carousel");
import moment from "moment";

export default {
  name: "LizMagazine",

  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      isLoading: true,
      magazines: [],
      slickSettings: {
        arrows: true,
        // centerMode: true,
        // centerPadding: "0px",
        // slidesToShow: 5,
        slidesToShow: 5,
        slidesToScroll: 5,
        swipeToSlide: true,
        infinite: true,
        // lazyLoad: "ondemand",
        responsive: [
          {
            breakpoint: 769,
            settings: {
              arrows: false,
              // slidesToShow: 3,
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getMagazines();
  },
  methods: {
    async getMagazines() {
      try {
        this.isLoading = true;
        const res = await this.$api.user.getArticles();
        if (res.status === 200) this.magazines = res.data.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY").toString();
      }
      return value;
    },
    replaceByDefaultImage(img) {
      img.target.src = require("../../assets/img/product-default.png");
    },
  },
};
</script>
<style scoped>
  .homepage-skeleton-article >>> .b-aspect {
    width: 100%;
  }
</style>
