<template>
  <PuSkeleton :class="[classActive]" height="600px" :id="id" />
</template>
<script>
export default {
  name: 'ImgSkeleton',
  props: ['src', 'classActive', 'alt', 'title'],
  data() {
    return {
      id: `img-${Math.floor(Math.random() * 1000000)}`,
    }
  },
  mounted() {
    this.setImage()
  },
  methods: {
    setImage() {
      const classActive = typeof this.classActive == 'object'
        ? Object.keys(this.classActive).filter(key => this.classActive[key]).join(' ')
        : this.classActive

      const img = document.createElement('img')
      img.className = classActive
      img.src = this.src
      img.alt = this.alt
      img.title = this.title
      img.onload = () => {
        const skeleton = document.getElementById(this.id)
        img.id = this.id
        skeleton?.parentNode.replaceChild(img, skeleton)
      }
      img.onerror = (e) => {
        this.$emit('error', e)
      }
    }
  },
  watch: {
    src() {
      this.setImage()
    },
    classActive() {
      this.setImage()
    },
    alt() {
      this.setImage()
    },
    title() {
      this.setImage()
    },
  }
}
</script>
