<template>
  <div class="product-box position-relative" :class="{ 'product-bp-exclusive': isBpExclusiveProduct }" @mouseenter="showProductHover" @mouseleave="hideProductHover">
    <div class="product-img-container">
      <router-link
        :to="'/product/' + product.slug"
        @click.native="
          firebaseSelectItem(product);
          $emit('closeSideWishlist');
        "
      >
        <img-skeleton
          :alt="`${product.brand_name} ${product.name}`"
          :title="product.name"
          :src="product.product_images[0].medium_image_url + '?w=300'"
          @error="replaceByDefaultImage"
          :classActive="{
            'product-img': true,
            'p-5 default-image': product.product_images[0].medium_image_url.includes(
              'default-image'
            ),
          }"
          width="100px"
          height="100px"
        />
        <b-img :src="product.active_promo_badge_url" class="promo-badge-img" alt="" v-if="product.active_promo_badge_url" />
      </router-link>
      <!-- <div class="product-badge">
        <div v-if="product.total_stock == 0" class="nostock-badge">
          SOLD OUT
        </div>
        <div v-else-if="isNew(product.created_date) <= 2" class="newin-badge">
          NEW IN
        </div>
        <div v-else-if="product.discount_type == 1" class="sale-badge">
          <span class="pt-1">SALE</span>
        </div>
        <div v-else-if="product.price_display < product.price" class="sale-badge">
          SAVE<span>{{ product.discount }}%</span>
        </div>
      </div> -->
      <div class="product-save" v-if="!hideAddToWishlist">
        <b-img-lazy
          v-if="$store.getters.getWishlist.find((el) => el.id == product.id)"
          :src="require('@/assets/img/wishlist-on.svg')"
          alt="Remove from wishlist"
          title="Remove from wishlist"
          width="18"
          class="cursor-pointer"
          @click.native="removeFromWishlist(product)"
        />
        <b-img-lazy
          v-else
          :src="require('@/assets/img/wishlist-off.svg')"
          alt="Add to wishlist"
          title="Add to wishlist"
          width="18"
          class="cursor-pointer"
          @click.native="addToWishList(product)"
        />
      </div>
      <!-- <div class="po-status-badge" v-if="product.status_stock == false">
        Pre-order
      </div> -->
      <!-- <div class="product-rating" v-if="product.average_rating > 0">
        <fawesome-pro variant="fas" icon="star" class="text-warning pr-1" />
        <b>{{ product.average_rating.toFixed(1) }}</b>
      </div> -->
    </div>
    <router-link
      :to="'/product/' + product.slug"
      @click.native="
        firebaseSelectItem(product);
        $emit('closeSideWishlist');
      "
    >
    <div class="product-info">
      <div class="product-brand">
        <PuSkeleton>
          {{ product.brand_name }}
        </PuSkeleton>
      </div>
      <div class="product-name line-clamp-2">
        <PuSkeleton>
          {{ product.name }}
        </PuSkeleton>
      </div>
      <div
        class="product-price pt-1"
        :class="{ 'sale-price': (product.product_type == 4 && product.discount_active_bundle) || product.discount_active || product.bundle_dynamic_price }"
        v-if="product.checkout_type != 1"
      >
        {{ product.bundle_dynamic_price ? $helpers.product.formatBundlePrice(product) : $helpers.format.price(product.price_display) }}
        <span class="d-flex align-items-center justify-content-center">
          <span v-if="product.discount_badge" class="discount-badge mr-1">{{ product.discount_badge }}</span>
          <span class="old-price" v-if="product.product_type == 4 && product.discount_active_bundle">{{ $helpers.product.formatBundlePriceOrigin(product) }}</span>
          <span class="old-price" v-else-if="product.discount_active">{{ $helpers.format.price(product.price) }}</span>
        </span> 
      </div>
      <div class="p-2" v-else>
        <p class="border-gold text-gold d-inline-block m-0 w-75">
          <small><i>Liz-Point</i></small
          ><br />
          {{ formatPrice(product.price_display) }}
        </p>
      </div>
      <div class="product-badge mt-1" v-if="product.badge_list && product.badge_list.length || product.greatest_voucher_discount">
        <span class="product-voucher-card-badge" v-if="product.greatest_voucher_discount">{{ `Disc. ${product.greatest_voucher_discount.discount_type == 1 ? $helpers.format.compactPrice(product.greatest_voucher_discount.discount) : product.greatest_voucher_discount.discount + '%' }` }}</span>
        <template v-for="(badge, i) in product.badge_list">
          <span v-if="badge.badge_text" :key="i" :style="{
            'color': badge.badge_fontcolor,
            'background': badge.badge_bgcolor,
          }">{{ badge.badge_text }}</span>
        </template>
      </div>
      <small v-if="!/fine|gift/i.test(product.category_name) && !product.bundle_dynamic_price">{{ formatPrice(product.installment_price_display) }} / 12 Mon</small>
      <!-- TODO Maybe Revamp DOM element to fixing the spacing between all element in the ProductBox.vue -->
      <div class="font-11px" :class="{ 'pt-1': product?.badge_list?.length && (/fine|gift/i.test(product.category_name) || product.bundle_dynamic_price) }" v-if="product.average_rating > 0">
        <fawesome-pro variant="fas" icon="star" class="text-warning pr-1" />
        <b>{{ product.average_rating.toFixed(1) }}</b>
      </div>
    </div>
    </router-link>
    <ProductHover v-if="detail" :product="detail" :installmentPriceDisplay="product.installment_price_display"
      @goto="firebaseSelectItem(product); $emit('closeSideWishlist')"
      @addWishlist="addToWishList(product)"
      @removeWishlist="removeFromWishlist(product)"
      @showModalCategorySize="$emit('showModalCategorySize', $event)"
      @showModalInstallmentCalculator="$emit('showModalInstallmentCalculator', $event)"
    />
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
const ProductHover = () => import('@/components/mains/ProductHover');

import axios from "axios";

export default {
  name: "ProductBox",
  components: {
    ProductHover
  },
  props: ['product', 'detailOnHover', 'hideAddToWishlist', 'isBpExclusiveProduct'],
  data() {
    return {
      timeout: null,
      detail: null,
    }
  },
  computed: {
    apiUrl: () => process.env.VUE_APP_API_URL,
    apiKey: () => process.env.VUE_APP_API_KEY,
  },
  methods: {
    showProductHover() {
      // if (this.product.badge_list.length)
      //   console.log(this.product);
      if (this.detailOnHover === undefined) return
      this.timeout = setTimeout(() => {
        if (!this.detail) this.getProductDetail(this.product.id)
      }, 500)
    },
    hideProductHover(){
      clearTimeout(this.timeout)
    },
    async getProductDetail(id) {
      try {
        const res = await this.$api.product.getProductDetail(id, false)
        if (res.status === 200) this.detail = res.data.data
        else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    getWishList() {
      axios
        .get(this.apiUrl + "users/products/wishlist/get", { 
          headers: {
            api_key: this.apiKey,
            token: this.$store.getters.getUserToken,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.$store.dispatch("setWishlist", response.data.data);
        });
    },
    addToWishList(product) {
      const token = this.$store.getters.getUserToken;
      if (token) {
        axios
          .post(
            this.apiUrl + "users/products/wishlist",
            {
              product_id: product.id,
            },
            {
              headers: {
                api_key: this.apiKey,
                token: token,
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            toaster.make(product.name + " has been added to your WishLiz", "success")
            this.$firebaseLogEvent("add_to_wishlist", {
              currency: "IDR",
              items: [
                {
                  item_id: product.id.toString(),
                  item_name: product.name,
                  item_brand: product.brand_name,
                  item_category: product.category3_name,
                  price: product.price_display,
                  size: null,
                  currency: "IDR",
                },
              ],
              value: product.price_display,
            });
            this.getWishList();
          });
      } else {
        this.$bvModal.show("modalLogin");
      }
    },
    removeFromWishlist(product) {
      axios
        .delete(this.apiUrl + "users/products/wishlist/" + product.id, {
          headers: {
            api_key: this.apiKey,
            token: this.$store.getters.getUserToken,
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          const data = this.$store.getters.getWishlist;
          data.splice(
            data.findIndex((el) => el.id == product.id),
            1
          );
          this.$store.dispatch("setWishlist", data);
          toaster.make(product.name + " has been removed from your WishLiz")
        });
    },
    isNew(date) {
      // https://stackoverflow.com/a/3224854
      const date1 = new Date(date);
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    // formatPrice: (value) =>
    //   value ? value.toLocaleString("id", { maximumFractionDigits: 2 }) : value,
    formatPrice(value) {
      return this.$helpers.format.price(value)
    },
    replaceByDefaultImage() {
      this.product.product_images[0].medium_image_url = require("@/assets/img/default-image.png");
    },
    firebaseSelectItem(data) {
      // when user clicks on a product link.
      this.$firebaseLogEvent("select_item", {
        currency: "IDR",
        items: [
          {
            item_id: data.id.toString(),
            item_name: data.name,
            item_brand: data.brand_name,
            currency: "IDR",
            price: data.price_display,
            quantity: data.total_stock,
          },
        ],
        value: data.price_display
      });
    },
  },
  watch: {
    product() {
      this.detail = null
    }
  },
};
</script>
<style scoped>
  .product-bp-exclusive {
    box-shadow: 2px 4px 8px rgba(255, 196, 112, 0.35);
  }
</style>